import Nav from './Nav'
import Svg from 'components/Svg'
import React, { useState, useEffect } from 'react'
// eslint-disable-next-line
const c = console.log

const Header = () => {
	const [anchorTarget, setAnchorTarget] = useState(null);

	// When page is loaded
	useEffect(() => {
		setAnchorTarget(document.getElementById('top'))
	},[])

	const handleClick = event => {
		event.preventDefault()
		anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	return (
		<header className='fl-sb header'>
			<div className='head_left fl-c-l'>
				<div onClick={handleClick} className='fl-c head_name'>
					<h1>
						<span className="_no_mobile">Ali Pordeli</span>
						<span className="_mobile">Ali</span>
					</h1>
				</div>
				<Nav />
			</div>
			<div className="head_right">
				<div className="info fl-c-l">
					<a className="textlink _no_mobile" href="https://www.hoborg.com" rel="nofollow, noreferrer" target="_blank">
						<span className="tl_prefix">Portfolio</span>
						<span className="tl_text">Go to my website</span>
						<Svg svg="link"/>
					</a>
					<a className="m_icon _mobile" href="https://www.hoborg.com" rel="nofollow, noreferrer" target="_blank">
						<Svg svg="site"/>
					</a>
					<a className="m_icon _mobile" href="mailto:hoborg@gmail.com?subject=A message from your CV 💼 👋" rel="nofollow, noreferrer" target="_blank">
						<Svg svg="mail"/>
					</a>
				</div>
				<h3 className="head_cv fl-c-r">
					<span className="_no_mobile">Curriculum vitae</span>
					<span className="_mobile">CV</span>
				</h3>
			</div>
		</header>
	)
}

export default Header