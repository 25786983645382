import HomeLinks from 'components/HomeLinks'
import Svg from 'components/Svg'

import { useSelector } from 'react-redux'

const Top = () => {
	const states = useSelector(state => state.mainStore)
	const menuItems = states.menuItems

	const menuList = menuItems.map(item  =>
		<HomeLinks item={item} key={item.id} />
	)

	return (
		<section id="section_top">
			<div className="anchor" id="top"></div>
			<div className="nametitle fl-co-l">
				<h2 className="_accent name">Ali Pordeli</h2>
				<h3 className="fl-co-l">
					<span>Sr. Lead Product Designer</span>
				</h3>
			</div>
			<div className="top_icons fl-c-l _no_mobile">
				<a className="m_icon" href="https://www.hoborg.com" rel="nofollow, noreferrer" target="_blank">
					<Svg svg="site"/>
				</a>
				<a className="m_icon" href="mailto:hoborg@gmail.com?subject=A message from your CV 💼 👋" rel="nofollow, noreferrer" target="_blank">
					<Svg svg="mail"/>
				</a>
			</div>
			<ul className="home_nav fl-co-l">
				{ menuList }
			</ul>
			<p className="about _mobile">I began my career as a user experience designer and web application designer in 2011; accumulatively, I have 10+ years of experience in UX and product design. In addition to running a successful business delivering designs and tools used by 50,000 designers and agencies, I also contracted with renowned companies like Adobe and Shopify. My strengths lie in leading design teams, designing, and architecting user experiences and applications with a specific focus on the web and design community.</p>
		</section>
	)
}

export default Top
