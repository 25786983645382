const Education = () => {
	return (
		<section>
			<div className="anchor" id="education"></div>
			<h2 className="section_title">Education</h2>
			<div className="se_parts fl-co-l">
				<div className="se_part">
					<h3 className="sepa_head">Master of Digital Media</h3>
					<h4 className="sepa_sub">Ryerson University</h4>
					<p>Toronto, 2020-2021</p>
				</div>
				<div className="se_part">
					<h3 className="sepa_head">Web Technologies Graduate Certificate</h3>
					<h4 className="sepa_sub">Harvard University</h4>
					<p>Cambridge, 2019-2020</p>
				</div>
				<div className="se_part">
					<h3 className="sepa_head">I.S.P. — Information Systems Professional Certificate</h3>
					<h4 className="sepa_sub">CIPS-Canada’s association of IT Professionals</h4>
					<p>Saskatchewan, 2019</p>
				</div>
				<div className="se_part">
					<h3 className="sepa_head">Bachelor of Fine Arts — in Visual Arts</h3>
					<h4 className="sepa_sub">Jacksonville University</h4>
					<p>Jacksonville, 2011-2014</p>
				</div>
			</div>
		</section>
	)
}

export default Education
