import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line
const c = console.log;

export const mainStore = createSlice({
	name: "mainStore",
	initialState: {
		navOpen: false,
		activeTabId: null,
		scrolled: false,
		menuItems: [
			{
				id: "career",
				text: "Career",
				top: null,
			},
			{
				id: "education",
				text: "Education",
				top: 0,
			},
			{
				id: "skills",
				text: "Technologies",
				top: null,
			},
			{
				id: "extras",
				text: "Special mentions",
				top: 0,
			},
		]
	},
	reducers: {
		setScrolled: (state, action) => {
			state.scrolled = action.payload;
		},
		navToggle: (state, action) => {
			if (action.payload === undefined) {
				state.navOpen = !state.navOpen;
			} else {
				state.navOpen = action.payload;
			}
		},
		setActiveTabId: (state, action) => {
			state.activeTabId = action.payload;
		},
		setMenuItemsTop: (state) => {
			let curScroll = window.scrollY;
			state.menuItems = state.menuItems.map((item) => {
				let el = document.getElementById(item.id);
				let rect = el.getBoundingClientRect();
				return { ...item, top: rect.top - 2 + curScroll };
			});
		}
	},
});

// Action creators are generated for each case reducer function
export const {
	navToggle,
	setActiveTabId,
	setMenuItemsTop,
	setScrolled
} = mainStore.actions;
export default mainStore.reducer;
