import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
// eslint-disable-next-line
const c = console.log;

const Links = ({ item, activeId, className }) => {


	const [anchorTarget, setAnchorTarget] = useState(null);

	// When page is loaded
	useEffect(() => {
		setAnchorTarget(document.getElementById(item.id))
	}, [item.id])


	const handleClick = event => {
		event.preventDefault()
		anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	return (
		<li>
			<a href={`#${item.id}`}
				onClick={handleClick}
				className={`${className} ${activeId === item.id ? 'active' : ''}`}>
				<span>{item.text}</span>
			</a>
		</li>
	)
}

Links.defaultProps = {
	className: 'link'
}

Links.propTypes = {
	className: PropTypes.string
  }

export default Links
