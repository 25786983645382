const Skills = () => {

	const skills = [
		{
			text: 'Shopify front-end and back-end design/development (.liquid)',
			value: 100
		},
		{
			text: 'Node.js // & Express.js and Webpack',
			value: 100
		},
		{
			text: 'Vue.js 2 & 3 // &  VueX and Vue Router // Nuxt.js',
			value: 100
		},
		{
			text: 'React.js & Redux // & Related libraries',
			value: 100
		},
		{
			text: 'CSS, SCSS & other processors',
			value: 100
		},
		{
			text: 'PHP',
			value: 80
		},
		{
			text: 'HTTP & REST // express.js and similars',
			value: 88
		},
		{
			text: 'Vanilla JavaScript // ES7 ',
			value: 100
		},
		{
			text: 'Ecommerce dev // Shopify, Magento & similars ',
			value: 90
		},
		{
			text: 'Git // & similar versioning technologies ',
			value: 90
		}
	]

	const apps = ['Xd','Ai','Ae']
	return (
		<section>
			<div className="anchor" id="skills"></div>
			<h2 className="section_title">Technologies & Tools</h2>
			<div className="se_parts appballs fl-c-l">
				{
					apps.map((ap,i) =>
						<div key={i} className="appball fl-c">
								<span>{ap}</span>
						</div>
					)
				}
				<div className="appicon">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 21"><path d="M0,0H14V7H7L0,0ZM0,7H7l7,7H7v7L0,14V7Z"/></svg>
				</div>
				<div className="appicon">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.08 246.08"><path d="M150.24,84.54c12.54-8.23,20.84-22.41,20.84-38.5C171.08,20.65,150.43,0,125.04,0H46.04C20.65,0,0,20.65,0,46.04c0,16.09,8.3,30.27,20.84,38.5C8.3,92.78,0,106.95,0,123.04s8.3,30.26,20.84,38.5C8.3,169.78,0,183.95,0,200.04c0,25.39,20.77,46.04,46.29,46.04s46.79-20.88,46.79-46.54v-42.45c8.18,7.45,19.05,11.99,30.96,11.99h1c25.39,0,46.04-20.65,46.04-46.04,0-16.09-8.3-30.27-20.84-38.5ZM93.08,15.08h31.96c17.07,0,30.96,13.89,30.96,30.96s-13.89,30.96-30.96,30.96h-31.96V15.08Zm-47.04,0h31.96v61.92h-31.96c-17.07,0-30.96-13.89-30.96-30.96S28.97,15.08,46.04,15.08ZM15.08,123.04c0-17.07,13.89-30.96,30.96-30.96h31.96v61.92h-31.98c-17.06-.01-30.94-13.89-30.94-30.96Zm62.92,76.5c0,17.35-14.23,31.46-31.71,31.46s-31.21-13.89-31.21-30.96,13.88-30.95,30.94-30.96h31.98v30.46Zm47.04-45.54h-1c-17.07,0-30.96-13.89-30.96-30.96s13.89-30.96,30.96-30.96h1c17.07,0,30.96,13.89,30.96,30.96s-13.89,30.96-30.96,30.96Z"/></svg>
				</div>
			</div>
			<div className="se_parts fl-co-l">
				{
					skills.map((skill,i) =>
						<div className="skill" key={i} >
							<div className="sk_bar">
								<span style={{ width: skill.value + '%' }}></span>
							</div>
							<p className="sk_text">{ skill.text }</p>
						</div>
					)
				}
			</div>
		</section>
	)
}
export default Skills
