import Header from 'components/Header'
import Home from 'pages/Home'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'



function CV() {
	const [loading, setLoading] = useState(true);
	const states = useSelector(state => state.mainStore)
	const activeTabId = states.activeTabId
	const scrolled = states.scrolled

	// When page is loaded
	useEffect(() => {
		setTimeout(() => setLoading(false), 100)
	},[])
	//
	return (
		<div className={`ap_cv${loading ? ' loading' : ''}${activeTabId ? ' _' + activeTabId : ''}${scrolled ? ' _scrolled' : ''}`}>
			<Header/>
			<Home/>
		</div>
	);
}

export default CV;
