import Svg from 'components/Svg'
import React, { useState, useEffect } from 'react'
// eslint-disable-next-line
const c = console.log;

const Links = ({ item }) => {
	const [anchorTarget, setAnchorTarget] = useState(null)

	// When page is loaded
	useEffect(() => {
		setAnchorTarget(document.getElementById('top'))
	},[])

	const handleClick = event => {
		event.preventDefault()
		anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	// When page is loaded
	useEffect(() => {
		setAnchorTarget(document.getElementById(item.id))
	}, [item.id])

	return (
		<li>
			<a href={`#${item.id}`}
				onClick={handleClick}
				className='home_link fl-c-l'>
				<span className="hl_text">{item.text}</span>
				<Svg svg="link"/>
			</a>
		</li>
	)
}

export default Links
