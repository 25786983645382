const Career = () => {
	return (
		<section>
			<div className="anchor" id="career"></div>
			<h2 className="section_title">Career</h2>
			<div className="se_parts fl-co-l">
				<div className="se_part">
					<h3 className="sepa_head">Lead Product Designer</h3>
					<h4 className="sepa_sub">Capintel Inc</h4>
					<p>Toronto, ON — 2022-Present</p>
					<h5 className="sepa_sublet">Responsibilities & role</h5>
					<ul className="sepa_list">
						<li>Leading a multidisciplinary team of 6+ team members, including product designers, product researchers, interface designers, and accessibility experts.</li>
						<li>Defining a new Design System details from scratch; that include specifications, behaviors, accessibility criteria and use case guidelines.</li>
						<li>Synthesizing research, client insights, and requirements to craft user experiences that connect people with the company’s products, services, and brands.</li>
						<li>Planning, leading, and facilitating design thinking workshops using modern methods and tools for clients and collaborators.</li>
						<li>Working with a product manager, engineers, product marketing, content strategy, research, and product analytics to create holistic end-to-end experiences.</li>
						<li>Contributing to the design by working in Figma to deliver agile solutions to the right problems, advocating for our diverse customer base with data and in-depth analysis to help meet their unique needs.</li>
					</ul>
				</div>
				<div className="se_part">
					<h3 className="sepa_head">Senior UX Design Lead / General Product Manager</h3>
					<h4 className="sepa_sub">QooQee LLC</h4>
					<p>Jacksonville, FL — 2014-2020</p>
					<h5 className="sepa_sublet">Responsibilities & role</h5>
					<ul className="sepa_list">
						<li>Serving as the creative point-of-contact and project lead for UX design projects.  </li>
						<li>Creating information architecture frameworks, content models, wireframes, content governance documentation, style guidelines, and priority guides to communicate the overall content plan.</li>
						<li>Creating UX research strategies and discovering new content needs for evaluating existing content and producing new content.</li>
						<li>Collaborating with team members and guiding the projects’ activities, strategy, and timeline.</li>
						<li>Producing boilerplates for plugin and design deliveries and supervising the development teams, guiding them through the provisions.</li>
						<li>Mentoring and onboarding junior team members.</li>
						<li>Leading, organizing, and facilitating design workshops with clients and partners.</li>
					</ul>
				</div>
				<div className="se_part">
					<h3 className="sepa_head">Senior UX Designer / Senior Web Application Designer (Multiple Contracts)</h3>
					<h4 className="sepa_sub">Adobe</h4>
					<p>San Francisco, CA — 2015-2019</p>
					<h5 className="sepa_sublet">Responsibilities & role</h5>
					<ul className="sepa_list">
						<li>Communicating and getting consensus on the design directions and procedures with various managers across Adobe, in multiple teams, such as Adobe XD team, Adobe Muse team, and Creative Cloud Learn team.</li>
						<li>Working closely with Product Managers, UX Design teams, Visual Design teams, and Development teams in Adobe and Shopify to help build the design and software interface and add new features to the software through extensibility products.</li>
						<li>Creating UX Designs and UX Design boilerplates to be included in Adobe software packages provided by Adobe to their end-users. </li>
						<li>Leading an internal team for designing and building intuitive dashboards and user controls for new features in the software and presenting the deliveries to Adobe’s design teams.</li>
					</ul>
				</div>
			</div>
		</section>
	)
}

export default Career
