import Links from './Links'
import { useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { navToggle, setActiveTabId, setMenuItemsTop, setScrolled } from 'redux/mainStore'

// eslint-disable-next-line
const c = console.log
let TOT

const Nav = () => {
	const node = useRef()
	// Redux - MainStore
	const states = useSelector(state => state.mainStore)
	const navOpen = states.navOpen
	const activeTabId = states.activeTabId
	const menuItems = states.menuItems

	const dispatch = useDispatch()

	const handleClick = useCallback(e => {
		if (node.current.contains(e.target)) return
		dispatch(navToggle(false))
	}, [dispatch])

	const handleScroll = useCallback(() => {
		// eslint-disable-next-line
		const curPos = window.scrollY;
		let curSection = menuItems.reduce((kol,item) => {
			let top = item.top
			if(top < 10) return [-1, 'top']
			dispatch(setScrolled(curPos > 200))
			return top <= curPos && top > kol[0] ? [top, item.id] : kol
		}, [-1, null])[1]

		if (curSection !== activeTabId) dispatch(setActiveTabId(curSection))
		// eslint-disable-next-line
	},[activeTabId, dispatch, TOT])

	const getAnchorPoints = useCallback(() => {
		dispatch(setMenuItemsTop())
		handleScroll()
		// eslint-disable-next-line
	},[handleScroll])

	useEffect(() => {
		// Mutable observer for watching
		getAnchorPoints()
		const observer = new MutationObserver(getAnchorPoints);
		observer.observe(document.getElementById('root'), {
			childList: true,
			subtree: true,
		});
		// On scroll event
		window.addEventListener('scroll', () => {
			clearTimeout(TOT)
			TOT = setTimeout(() => handleScroll(), 100)
		})
		// Menu trigger events
		document.addEventListener("mousedown", handleClick);
		return () => {
		  document.removeEventListener("mousedown", handleClick);
		}
		// eslint-disable-next-line
	}, [getAnchorPoints])



	const menuList = className => menuItems.map(item  =>
		<Links className={ className ? className : 'link'} item={item} key={item.id} activeId={activeTabId} />
	)

	return (
		<div ref={ node }	 className='nav_wrapper fl-c-l'>
			<div className="nav_trigger fl-c" onClick={() => dispatch(navToggle())}>
				<div className={`mt_parts ${navOpen ? 'open' : ''}`}>
					<span className="mt_part mtp_1"></span>
					<span className="mt_part mtp_2"></span>
					<span className="mt_part mtp_2 mtp_22"></span>
					<span className="mt_part mtp_3"></span>
				</div>
			</div>
			{/* itemId, active, itemText  */}
			<ul className={`nav fl-co-l${navOpen ? ' open' : ''}`}>
				{menuList()}
			</ul>
			<ul className="desk_nav fl-c">
				{menuList('link_d')}
			</ul>
		</div>
	)


}

export default Nav
