import React from 'react'
import Svg from 'components/Svg'
import Top from 'sections/Top'
import Career from 'sections/Career'
import Education from 'sections/Education'
import Skills from 'sections/Skills'
import Extras from 'sections/Extras'

const Home = () => {
	return (
		<main className="cv_page">
			<div className="main_wrapper fl-st">
				<div className="main_left fl-co">
					<Top />
					<Career />
					<Education />
					<Skills />
					<Extras />
					{/* <div className="me_image">
						<Svg svg="me"/>
					</div> */}
				</div>
				<div className="main_right fl-co _no_mobile">
					<div className="title_bar">
						<span>About</span>
					</div>
					<p>I began my career as a user experience designer and web application designer in 2011; accumulatively, I have 10+ years of experience in UX and product design. In addition to running a successful business delivering designs and tools used by 50,000 designers and agencies, I also contracted with renowned companies like Adobe and Shopify. My strengths lie in leading design teams, designing, and architecting user experiences and applications with a specific focus on the web and design community.</p>
				</div>
			</div>
		</main>
	)
}

export default Home
