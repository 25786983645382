import React from 'react'

const Svg = ({ svg, className }) => {
	const assetsDir = require(`assets/${ svg }.svg`);
	return (
		<div className={`svg ${className ? className : ''}`} dangerouslySetInnerHTML={{__html: assetsDir }} />
	)
}

export default Svg
