const Extras = () => {
	return (
		<section>
			<div className="anchor" id="extras"></div>
			<h2 className="section_title">Special mentions</h2>
			<div className="se_parts fl-co-l">
				<div className="se_part">
					<h3 className="sepa_head">Adobe’s Development Grant</h3>
					<p>2018</p>
				</div>
				<div className="se_part">
					<h3 className="sepa_head">Speaker at Adobe Max conference</h3>
					<h4 className="sepa_sub">Adobe System Incorporated</h4>
					<p>2014 / 2015 / 2016</p>
				</div>
				<div className="se_part">
					<h3 className="sepa_head">Interviewed by the Forbes Magazine</h3>
					<h4 className="sepa_sub">Topic: Creating Professional Websites</h4>
					<p>2014</p>
				</div>
			</div>
		</section>
	)
}

export default Extras
